<template>
  <div class="dropzone">
  </div>
</template>

<script>
export default {
  setup() {
    return {}
  }
}
</script>

<style lang="scss" scoped>

*{
  -webkit-transition: all 0ms ease !important;
  transition: all 0ms ease!important;
}
.dropzone {
  cursor: pointer;
  position:absolute;
  width: inherit;
  height: inherit;
  left:0px;
  border-radius: 16px;
  box-sizing: border-box;
  transition: 0.3s ease all;
  text-align: left;
  background-color:rgba(0, 108, 62, 0.05);
  background-image: url(../../../public/img/library/uploader.svg), url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='rgba(0, 108, 62, 0.15)' stroke-width='10' stroke-dasharray='6%2c 15' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  background-position: center 37px, 1px;
  background-size: 40px, 178px;
  background-repeat: no-repeat;
  font-weight: 400;
  text-align: center;
  color: #787878;
  line-height: 16px;
  white-space: initial;
  width: 180px;
  height: 136px;
  z-index: 0;
  left: calc(50% - 90px);
}
.tag{
  .dropzone{
    background-image:url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='rgba(0, 108, 62, 0.15)' stroke-width='10' stroke-dasharray='6%2c 15' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    position: relative;
    background-size: 100%;
    background-position: 0px;
    width: 100%;
    height: auto;
    flex: 1;
    left: auto;
    aspect-ratio: 4/3;
  }
}
.dropzone::before{
  content: '';
  z-index: 1;
  position: absolute;
  top: -1px;
  right: -1px;
  background-color: #fff;
  background-image: url(../../../public/img/library/edit.svg);
  background-position: center;
  background-repeat: no-repeat;
  // @media screen and (min-width: 27 01px) and (max-width: 3840px){
  //   height: 46px;
  //   width: 46px;
  //   border-radius: 0 9px 0 12px;
  // }
  // DESKTOP
  // @media screen and (min-width: 1025px) and (max-width: 27 00px){
  @media screen and (min-width: 1025px){
    height: 32px;
    width: 32px;
    border-radius: 0 9px 0 10px;
  }
  // LAPTOP
  @media screen and (min-width: 901px) and (max-width: 1024px){
    height: 35px;
    width: 35px;
    border-radius: 0 9px 0 7px;
    // top: 4px;
    // right: 4px;
  }
  // TABLET
  @media screen and (min-width: 481px) and (max-width: 900px){
    height: 32px;
    width: 32px;
    border-radius: 0 9px 0 6px;
    // top: 4px;
    // right: 4px;
  }
  // MOBILE
  @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape){
    height: 32px;
    width: 32px;
    border-radius: 0 9px 0 5px;
    background-size: 20px;
    // top: 4px;
    // right: 4px;
  }
}
.dropzone::after {
  content: 'No Image';
  width: 230px;
  position: absolute;
  height: 32px;
  display: inline-block;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100%;
  bottom: calc(50% - 18px);
  font-size: 1rem;
  line-height: 24px;
  font-weight: 600;
}
</style>
